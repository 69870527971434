<template>
  <div class="Order" v-if="!isLoading">
    <v-container fluid>
      <v-expansion-panels v-for="item in order" :key="item.id">
        <v-expansion-panel>
          <v-expansion-panel-header
            ><v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">編號</th>
                    <th class="text-left">狀態</th>
                    <th class="text-left">時間</th>
                    <th class="text-left">出貨狀態</th>
                    <th class="text-left">總計</th>
                    <th class="text-left">名字</th>
                    <th class="text-left">地址</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ item.order_id }}</td>
                    <td>
                      <v-chip :color="getColor(item.is_pay)" dark>
                        {{ item.is_pay == 1 ? '已付款' : '未付款' }}
                      </v-chip>
                    </td>
                    <td>{{ item.create_time }}</td>
                    <td>
                      <v-chip :color="getColor(item.is_pay)" dark>
                        {{ '部分出貨' }}
                      </v-chip>
                    </td>
                    <td>{{ item.order_total }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.address }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table></v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-row>
              <v-col>
                <v-card>
                  <v-row v-for="item2 in item.orderInfo" :key="item2.id">
                    <v-col>{{ item2.item_name }}</v-col>
                    <v-col>{{ item2.amount }}件</v-col>
                    <v-col>${{ item2.item_total }}</v-col>
                    <v-col class="red--text">
                      <v-chip :color="getColor(item2.item_shipment)" dark>
                        {{ item2.item_shipment == 1 ? '已出貨' : '未出貨' }}
                      </v-chip>
                    </v-col>
                    <v-col v-if="item2.item_shipment == 0"></v-col>
                    <v-col v-if="item2.item_shipment == 1">{{ item2.item_shipment_no }}</v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-divider class="ma-5"></v-divider>
      </v-expansion-panels>
      <h1 class="text-center" v-if="order.length == 0">您還沒有任何訂單記錄</h1>
    </v-container>
  </div>
</template>

<script>
import { showLoading, hideLoading } from '@/utils/loading';
//import { userAPI } from "@/api/user/api";
import axios from 'axios';

export default {
  data() {
    return {
      headers: [
        {
          text: '編號',
          align: 'start',
          value: 'order_id',
        },
        { text: '狀態', value: 'is_pay' },
        { text: '時間', value: 'create_time' },
        { text: '總金額', value: 'order_total' },
        { text: '名字', value: 'name' },
        { text: '地址', value: 'address' },
      ],
      order: [],
      isLoading: true,
    };
  },
  created() {
    showLoading();
    if (this.$store.state.userInfo.member_id.indexOf('A') === -1) {
      axios
        .post('http://web.hilary.com.tw:8085/getapi?type=GETORDERLIST', {
          user_id: this.$store.state.userInfo.member_id,
        })
        .then((res) => {
          this.order = res.data.data;
          this.getOrderInfo();
        })
        .catch((err) => {
          console.log('error', err);
          this.$Message.error('取得訂單失敗');
        });
    } else {
      axios
        .post('http://web.hilary.com.tw:8085/getapi?type=GETORDERLISTCUSTOMER', {
          user_id: this.$store.state.userInfo.member_id,
        })
        .then((res) => {
          this.order = res.data.data;
          this.getOrderInfo();
        })
        .catch((err) => {
          console.log('error', err);
          this.$Message.error('取得訂單失敗');
        });
    }
    // userAPI.getOrderList().then((res) => {
    //   console.log(res);
    //   this.order = res.data.data;
    //   this.getOrderInfo();
    // });
    setTimeout(() => {
      this.isLoading = false;
      this.order.forEach((element) => {
        element.orderInfo.sort(function (a, b) {
          return a.item_id - b.item_id;
        });
      });
      hideLoading();
    }, 3000);
  },
  methods: {
    getOrderInfo() {
      this.order.forEach((element) => {
        axios
          .post('http://web.hilary.com.tw:8085/getapi?type=GETORDERLISTINFO', {
            order_id: element.order_id,
          })
          .then((res) => {
            element.orderInfo = res.data.data;
          })
          .catch((err) => {
            console.log('error', err);
            this.$Message.error('取得訂單失敗');
          });
      });
    },
    // getOrderInfo() {
    //   this.order.forEach((element) => {
    //     userAPI.getOrderInfo(element.order_id).then((res) => {
    //       element.orderInfo = res.data.data;
    //     });
    //   });
    // },
    getColor(status) {
      if (status == 0) return 'orange';
      if (status == 1) return 'green';
    },
  },
};
</script>
